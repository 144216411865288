import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

import { lingua_it } from "../i18n/gdpr-it-IT";
import { lingua_en } from "../i18n/gdpr-en-GB";
import { lingua_es } from "../i18n/gdpr-es-ES";
import { lingua_fr } from "../i18n/gdpr-fr-FR";

import { owner } from "../data";

const Withdrawal = (props) => {
  let lingua = {};

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it.recesso;
      break;
    case "fr-FR":
      lingua = lingua_fr.recesso;
      break;
    case "es-ES":
      lingua = lingua_es.recesso;
      break;
    case "en-GB":
      lingua = lingua_en.recesso;
      break;
    default:
      lingua = lingua_it.recesso;
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3">{lingua.titolo}</h1>

          <p>
            {lingua.testo_01} {owner.appUrl}, {lingua.testo_02}
          </p>
          <p>{lingua.testo_03}</p>
          <p>
            {owner.appUrl} {lingua.testo_04}
          </p>
          <p>
            {lingua.testo_05} {owner.appUrl}: {lingua.testo_06} {owner.appUrl},{" "}
            {lingua.testo_07}
          </p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_01}</h2>
          <ul>
            <li>{lingua.testo_08}</li>
            <li>{lingua.testo_09}</li>
            <li>{lingua.testo_10}</li>
            <li>{lingua.testo_11}</li>
          </ul>

          <p className="uk-text-small">
            {lingua.testo_26} {owner.azienda} {lingua.testo_27} {owner.email}{" "}
            {lingua.testo_28} {owner.azienda} {owner.indirizzo} - {owner.citta}.
          </p>

          <Link to="/">{lingua.torna}</Link>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(Withdrawal);
